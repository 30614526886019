import React from "react";

const VidEmbed = ({ url, caption }) => {
  return (
    <div className="w-full">
      <iframe
        className="w-full embed-vid-player mb-4"
        src={url}
        title="What to Expect on Your First Visit - Portland AM Northwest News Segment"
        frameborder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <p className="text-gray-700 italic">{caption}</p>
    </div>
  );
};

export default VidEmbed;
